import React, { useState, useEffect } from "react"
import { useParams, Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Form, Button } from 'react-bootstrap'

import { useAxios } from '../../hooks/Axios.hook'

import ButtonCopyLink from '../../components/ButtonCopyLink'


function LinkForm() {

    const { getAxiosInstance } = useAxios()

    const initialState = {
      real_url: "https://pomoschryadom.ru",
      client_id: "",
      page_url: "",
      comment: "",
      id_fake: false
    }

    const params = useParams()

    const [formData, setFormData] = useState(initialState)
    const [sequence, setSequence] = useState("")


    function handelChange(event) {
      
      const {name, value, type, checked} = event.target
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      }))

    }

    useEffect(() => {
      if (params.link_id) {
        getAxiosInstance.get(`/links/${params.link_id}`)
        .then((response) => {
          const respData = response.data
          setFormData(respData)
        }).catch((error) => {

          if (error.response.status === 401) {
            console.log(error)
          } else {
            toast(`Произошла ошибка: ${error}`)
            console.log(error)
          }
          
        })
      }
    }, [])
    

    function resetForm() {
      setFormData(initialState)
    }


    function handleAddSubmit(event) {
      event.preventDefault()

      const data = {
        ...formData,
        client_id: parseInt(formData.client_id),
      }

      getAxiosInstance.post(`/links`, data)
        .then(response => {
          resetForm()
          setFormData(response.data)
          
          toast("Ссылка добавлена")

        }).catch((error) => {

          if (error.response.status === 401) {
            console.log(error)
          } else {
            toast(`Произошла ошибка: ${error}`)
            console.log(error)
          }

        })
    }

    function handleEditSubmit(event) {
      event.preventDefault()

      const data = {
        ...formData,
        client_id: parseInt(formData.client_id),
      }

      console.log(data)

      getAxiosInstance.put(`/links/${params.link_id}`, data)
        .then((response) => {
          console.log(response.data)
          toast("Данные изменены")
        })
        .catch((error) => {
          
          if (error.response.status === 401) {
            console.log(error)
          } else {
            toast(`Произошла ошибка: ${error}`)
            console.log(error)
          }
          
        })
    }

    return (
      <>
        <Form onSubmit={ !params.link_id ? handleAddSubmit : handleEditSubmit }>

          <Form.Group className="mb-3" controlId="is_fake">
            <Form.Check type="switch" id="is_fake" name="is_fake" onChange={handelChange} label="Это тестовая ссылка" checked={formData.is_fake} />
          </Form.Group>

          <div className="input-group mb-3">
            <span className="input-group-text col-md-4">Реальная ссылка</span>
            <input type="url" required className="form-control col-md-8" name="real_url" onChange={handelChange} value={formData.real_url} />
          </div>

          <div className="input-group mb-3">
            <span className="input-group-text col-md-4">ID случая</span>
            <input type="number" required className="form-control col-md-8" name="client_id" onChange={handelChange} value={formData.client_id} />
          </div>

          <div className="input-group mb-3">
            <span className="input-group-text col-md-4">Ссылка на страницу</span>
            <input type="url" className="form-control col-md-8" name="page_url" onChange={handelChange} value={formData.page_url} />
          </div>

          <Form.Group className="mb-3" controlId="comment">
            <Form.Label>Комментарий</Form.Label>
            <Form.Control as="textarea" rows={5} name="comment" onChange={handelChange} value={formData.comment} />
          </Form.Group>


          <Button variant="success" type="submit">
            Сохранить
          </Button>
          <Link className="btn btn-light m-1" aria-current="page" to="/links">К списку ссылок</Link>

          { (formData.sequence) ? <ButtonCopyLink short_url={ `${process.env.REACT_APP_SITE_URL}/${ formData.sequence }` } /> : <></> }
        </Form>
        <h2 className="text-center mt-5">
          { (formData.sequence) ? `${process.env.REACT_APP_SITE_URL}/${ formData.sequence }` : <></> }
        </h2>
      </>
    )
  }

  export default LinkForm