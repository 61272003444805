import './App.css'
import React, { useEffect } from "react";
import { BrowserRouter as Router } from 'react-router-dom';

import { useRoutes } from './routes';




function App() {

    useEffect(() => {
      document.querySelector("html").setAttribute("data-bs-theme", "dark");
    }, []);


    const routes = useRoutes();

    return (
        <Router>
          { routes }
        </Router>
      )
    }

export default App;
