import React, { useState } from "react"

import { ToastContainer, toast } from 'react-toastify'

import { useAuth } from '../hooks/Auth.hook'
import { useAxios } from '../hooks/Axios.hook'


function SignIn() {

  const { signin } = useAuth()

  const initialState = {
    email: "",
    password: "",
  }

  const { getAxiosInstance } = useAxios()

  const [formData, setFormData] = useState(initialState);


  function handelChange(event) {
    const {name, value, type, checked} = event.target
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value
    }))
  }


  function handleSubmit(event) {
    event.preventDefault();


    getAxiosInstance.post(`/signin`, formData)
      .then(response => {

        if (response.data.access_token && response.data.refresh_token) {
          signin(response.data.access_token, response.data.refresh_token)
        } else {
          toast(`Произошла ошибка обработки`)
        }

        window.location.replace("/links")

      }).catch((error) => {
        if (error.response) {
          toast(`Произошла ошибка: ${error.response.data.error}`)
          // console.log(error.response.data.detail);
          console.log(error.response.data.error);
        } else {
          toast(`Произошла ошибка: ${error}`)
          console.log(error);
        }
        
        
      });

  }

  return (
    <div className="main_container h-100">
        <ToastContainer />
        <div className="container h-100">
          <div className="row h-100 justify-content-center align-items-center">
            <div className="col-4">
              <h5 className="text-center">LinkShorter</h5>
              <form onSubmit={handleSubmit}>
                <div className="input-group mb-3">
                  <span className="input-group-text col-md-6">Email</span>
                  <input type="email" required className="form-control col-md-6" name="email" id="email" onChange={handelChange} value={formData.email} />
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text col-md-6">Пароль</span>
                  <input type="password" required className="form-control col-md-6" name="password" id="password" onChange={handelChange} value={formData.password} />
                </div>
                <div className="mb-3 text-center">
                  <button className="btn btn-primary" type="submit">Войти</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    
  )
}

export default SignIn;