import React, { useState, useEffect } from "react"
import { useParams, Link } from 'react-router-dom'

import Navbar from '../../components/AppNavbar'
import Header from '../../components/Header'
import LinkForm from './LinkForm'

import { ToastContainer, toast } from 'react-toastify'
import ClickList from "../click/ClickList"

function LinkEdit() {

  const params = useParams()

  return (
    <div className="main_container">
      <Navbar />
      <ToastContainer />
      <div className="container mt-5">
        <div className="row">
          <div className="offset-2 col-8">
          <Header title="Изменить ссылку" />
          <LinkForm
            link_id={params.link_id}
          />
          </div>
        </div>
      </div>
      <ClickList/>
    </div>
  )
}

export default LinkEdit;