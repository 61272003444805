import React, { useState } from 'react'
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'

import SignIn from './pages/SignIn'
import LinkList from './pages/link/LinkList'
import LinkAdd from './pages/link/LinkAdd'
import LinkEdit from './pages/link/LinkEdit'


import { useAuth } from './hooks/Auth.hook'




export const useRoutes = () => {


  const { getAccessToken, getIsAdmin, getIsAccessTokenExpired } = useAuth()

  const [accessToken, setAccessToken] = useState(getAccessToken())
  const [isAdmin, setIsAdmin] = useState(getIsAdmin())
  // const [isAccessTokenExpired, setIsAccessTokenExpired] = useState(getIsAccessTokenExpired())


  if (!!accessToken && isAdmin) {

      return (
        <Routes>
          <Route path='/links/page/:page' element={<LinkList/>} />
          <Route path='/add-link' element={<LinkAdd/>} />
          <Route path='/edit-link/:link_id' element={<LinkEdit/>} />
          <Route path='*' element={<Navigate to="/links/page/1" />} />
        </Routes>
      
      )
    }  else if (!!accessToken && !isAdmin) {
      return (
      <Routes>
        <Route path='/links/page/:page' element={<LinkList/>} />
          <Route path='/add-link' element={<LinkAdd/>} />
          <Route path='/edit-link/:link_id' element={<LinkEdit/>} />
          <Route path='*' element={<Navigate to="/links/page/1" />} />
      </Routes>
      )
    } else {
      return (
        <Routes>
          <Route path='/signin' element={<SignIn/>} />
          <Route path='*' element={<Navigate to="/signin" />} />
        </Routes>
      )
    }

}