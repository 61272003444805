import React, { useState } from 'react';



function Search(props) {

  function handelChange(data) {
    props.setSearch(data.target.value);
  }

  return (
    <>
      <div className="input-group">
        <input type="text" name="search" value={props.search} onChange={handelChange} className="form-control" />
        <span className="input-group-text">Поиск</span>
      </div>
      <div className="form-text mb-3">
        Для поиска используйте ID случая
      </div>
    </>
  )
}

export default Search;