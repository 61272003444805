import React, { useState, useEffect } from "react"
import { useParams, Link } from 'react-router-dom'

import Navbar from '../../components/AppNavbar'
import Header from '../../components/Header'
import Search from '../../components/Search'
import DateCell from '../../components/DateCell'
import Pagination from '../../components/Pagination'
import ButtonCopyLink from '../../components/ButtonCopyLink'

import { ToastContainer, toast } from 'react-toastify'

import { Container, Row, Col, Table, Button } from 'react-bootstrap'

import { useAxios } from '../../hooks/Axios.hook'


function ButtonEditLink(props) {
  return(
    <Link className="btn btn-primary m-1" aria-current="page" to={`/edit-link/${props.link_id}`}>Изменить</Link>
  )
}

function ButtonAddLink() {
  return(
    <Link className="btn btn-success m-1" aria-current="page" to={`/add-link`}>Добавить</Link>
  )
}




function LinkList() {


  return (
    <>
      <Navbar />
      <ToastContainer/>
      <Container fluid={true}>
        <Row>
          <Col lg={12}>
            <Header title="Список ссылок" />
            <LinkTable/>
          </Col>
        </Row>
      </Container>
    </>
    )
  }


function LinkTable() {

  const params = useParams()

  const page = params.page

  const { getAxiosInstance } = useAxios()

  const [links, setLinks] = useState([])
  const [search, setSearch] = useState("")
  const [pageCount, setPageCount] = useState(0)

  useEffect(() => {

    const url = search.trim() ? `/links/search/${search}` : `/links/page/${page}`

    getAxiosInstance.get(url)
      .then((response) => {
        const respData = response.data
        console.log(respData)
        setLinks(respData.links)
        setPageCount(respData.page_count)
      }).catch((error) => {
        
        if (error.response.status === 401) {
          console.log(error)
        } else {
          toast(`Произошла ошибка: ${error}`)
          console.log(error)
        }
        
      });
    
  }, [search]);
  


  return (
    <>
      <Search search={search} setSearch={setSearch} />
      <div className="mb-3">
        <ButtonAddLink/>
        <Table hover variant="dark">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Это тестовая ссылка</th>
              <th scope="col">Реальная ссылка</th>
              <th scope="col">Укороченная ссылка</th>
              <th scope="col">ID случая</th>
              <th scope="col">Страница клиента</th>
              <th scope="col">Комментарий</th>
              <th scope="col">Дата создания</th>
              <th scope="col">Дата изменения</th>
              <th scope="col">Пользователь</th>
              <th scope="col">Количество срабатываний</th>
              <th scope="col">Действия</th>
            </tr>
          </thead>
          <tbody>
            { console.log(links) }
            { (links.length > 0)
              ? links.map(link => 

                <tr key={link.id}>
                  <th scope="row">{link.id}</th>
                  <td className="text-center">{ link.is_fake ? "Да" : "Нет" }</td>
                  <td>{ link.real_url }</td>
                  <td>
                    { `${process.env.REACT_APP_SITE_URL}/${ link.sequence }` }
                    <p className="text-center">
                      <ButtonCopyLink short_url={ `${process.env.REACT_APP_SITE_URL}/${ link.sequence }` } />
                    </p>
                  </td>
                  <td>{ link.client_id }</td>
                  <td>{ link.page_url }</td>
                  <td>{ link.comment }</td>
                  <td><DateCell date={link.created_at} /></td>
                  <td><DateCell date={link.updated_at} /></td>
                  <td>{ link.user.email }</td>
                  <td className="text-center">{ link.clicks.length }</td>
                  <td>
                    <ButtonEditLink link_id={ link.id }/>
                  </td>
                </tr>
                
              )
            : <tr><td colSpan="11">Нет данных</td></tr>
          }
          </tbody>
        </Table>
        <ButtonAddLink/>
        <Pagination pageCount={pageCount} />
      </div>
    </>
  )

}

export default LinkList;