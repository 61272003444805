import React, { useState, useEffect } from 'react'



function DateCell(props) {

  const [date, setDate] = useState("")
  const [time, setTime] = useState("")

  useEffect(() => {
    let fullDate = new Date(props.date);
    //let dateStr = fullDate.toLocaleString("ru-Ru")
    let dateStr = fullDate.toLocaleDateString("ru-Ru")
    let timeStr = fullDate.toLocaleTimeString("ru-Ru")

    setDate(dateStr)
    setTime(timeStr)
  })

  return(
    <div>
      <p className="mb-0">{date}</p>
      <p className="mb-0">{time}</p>
    </div>
  )
}

export default DateCell