import React from 'react'


function Header(props) {
  return (
    <div className="mb-3">
      <h4 className="text-light">{ props.title }</h4>
    </div>
  )
}

export default Header