import { useCallback, useState } from 'react'
import { useAuth } from './Auth.hook'
import axios from 'axios'

const accessTokenStorageName = 'accessToken'
const refreshTokenStorageName = 'refreshToken'

export function useAxios() {


  const { getAccessToken, getRefreshToken } = useAuth()

  const getAxiosInstance = useCallback(axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 5000,
    headers: { 
      // 'Authorization': `Bearer ${getToken()}`,
      'Access-Control-Allow-Origin': process.env.REACT_APP_API_URL,
      // 'AccessToken': `${getAccessToken()}`,
    }
  }))


  getAxiosInstance.interceptors.request.use(
    (config) => {
      const token = getAccessToken();
      if (token) {
        config.headers["AccessToken"] = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );


  getAxiosInstance.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config

      if (originalConfig.url !== "/signin" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          console.log(getRefreshToken())
  
          try {
            const rs = await getAxiosInstance.post("/refresh", {
              'RefreshToken': `${getRefreshToken()}`,
              // headers: { 
              //   // 'Authorization': `Bearer ${getToken()}`,
              //   'Access-Control-Allow-Origin': process.env.REACT_APP_API_URL,
              //   //'RefreshToken': `${getRefreshToken()}`,
              //   'RefreshToken': "asdasdasdasdasd",
              // },
              
              // refreshToken: TokenService.getLocalRefreshToken(),
            });
  
            const { newAccessToken } = rs.data
            console.log(rs.data)
            
            localStorage.setItem(accessTokenStorageName, rs.data.access_token)
            localStorage.setItem(refreshTokenStorageName, rs.data.refresh_token)
            // TokenService.updateLocalAccessToken(accessToken);
  
            return getAxiosInstance(originalConfig)
          } catch (error) {
            return Promise.reject(error)
          }
        }

        if (err.response.status === 403) {
          localStorage.removeItem(accessTokenStorageName)
          localStorage.removeItem(refreshTokenStorageName)
          window.location = "/signin"
        }
      }
  
      return Promise.reject(err);
    }
  );


  return { getAxiosInstance }


  
  
  
} 