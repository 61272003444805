import React, { useState, useEffect } from "react"
import { useParams, Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import { Container, Form, Button } from 'react-bootstrap'

import { useAxios } from '../../hooks/Axios.hook'


function ClickForm(props) {

    const { getAxiosInstance } = useAxios()

    const initialState = {
      id_fake: false,
      comment: "",
    }

    const params = useParams();

    const [formData, setFormData] = useState(initialState);


    function handelChange(event) {
      
      const {name, value, type, checked} = event.target
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      }))

    }

    useEffect(() => {
      if (params.link_id) {
        getAxiosInstance.get(`/clicks/${props.click_id}`)
        .then((response) => {
          const respData = response.data;
          setFormData(respData)
          
          console.log(respData)
          
        }).catch((error) => {
          
          if (error.response.status === 401) {
            console.log(error)
          } else {
            toast(`Произошла ошибка: ${error}`)
            console.log(error)
          }
          
        });
      }
    }, []);
    


    function handleEditSubmit(event) {
      event.preventDefault();

      const data = {
        ...formData,
        client_id: parseInt(formData.client_id),
      }


      getAxiosInstance.put(`/clicks/${props.click_id}`, data)
        .then((response) => {
          toast("Данные изменены");
          props.getAllClicks()
          props.handleClickModalClose()

        })
        .catch((error) => {
          
          if (error.response.status === 401) {
            console.log(error)
          } else {
            toast(`Произошла ошибка: ${error}`)
            console.log(error)
          }
          
        });;
    }

    return (
      
      <Form onSubmit={ handleEditSubmit }>

        <Form.Group className="mb-3" controlId="is_fake">
          <Form.Check type="switch" id="id_fake" name="id_fake" onChange={handelChange} label="Тестовое срабатывание" checked={formData.id_fake} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="comment">
          <Form.Label>Комментарий</Form.Label>
          <Form.Control as="textarea" rows={10} name="comment" onChange={handelChange} value={formData.comment} />
        </Form.Group>
        
        <p className="mb-0"><strong>UserAgent:</strong></p>
        <div className="mb-3">{ formData.user_agent }</div>

        <Button variant="primary" type="submit">
          Сохранить
        </Button>
        
      

      </Form>
    )
  }

  export default ClickForm;