import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap';

import ClickForm from "./ClickForm";

function ClickEditModal(props) {

  const [showClickModal, setShowClickModal] = useState(false);

  const handleClickModalClose = () => setShowClickModal(false);
  const handleClickModalShow = () => setShowClickModal(true);


  return (
    <>
      <Button className="nextButton" onClick={handleClickModalShow}>
        Изменить
      </Button>

      <Modal show={showClickModal} onHide={handleClickModalClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Изменить срабатывание</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ClickForm
            click_id={props.click_id}
            getAllClicks={props.getAllClicks}
            handleClickModalClose={handleClickModalClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ClickEditModal;
