import { React, useEffect } from 'react'
import { useParams, Navigate } from 'react-router-dom'


function Pagination(props) {

  const params = useParams()

  const currentPage = parseInt(params.page)

  const pageArr = []

  let prevPageDots = true
  let nextPageDots = true

  let alwaysShownPageNumbers = [
    1,
    currentPage - 1,
    currentPage,
    currentPage + 1,
    props.pageCount
  ]

  // console.log(currentPage)
  // console.log(props.pageCount)

  if (props.pageCount <= 1) return


  // If user take wrong page number which is lower or higher than existing
  if (currentPage <= 0) return <Navigate to="/links/page/1" />
  if (props.pageCount !== 0 && currentPage > props.pageCount) return <Navigate to="/links/page/3" />



  for (let i = 1; i <= props.pageCount; i++) {

    if (i === 1) {
      let prevLink = `/links/page/${currentPage - 1}`
      pageArr.push(<li key={i} className={ (currentPage <= 1) ? "page-item disabled" : "page-item" }><a href={prevLink} className="page-link">Предыдущая</a></li>)

      
    }

    if (props.pageCount < 4 || alwaysShownPageNumbers.includes(i)) {
      let link = `/links/page/${i}`
      pageArr.push(<li key={i + 1} className={ (currentPage === i) ? "page-item active" : "page-item" } ><a className="page-link" href={link}>{i}</a></li>)
    } else if(prevPageDots && i < currentPage) {
      prevPageDots = false
      pageArr.push(<li className="page-item disabled"><a className="page-link" href="#">...</a></li>)
    } else if(nextPageDots && i > currentPage) {
      nextPageDots = false
      pageArr.push(<li className="page-item disabled"><a className="page-link" href="#">...</a></li>)
      
    }

    

    if (i === props.pageCount) {
      let nextLink = `/links/page/${currentPage + 1}`
      pageArr.push(<li key={i + 2} className={ (currentPage >= props.pageCount) ? "page-item disabled" : "page-item" }><a href={nextLink} className="page-link">Следующая</a></li>)
    }
  }

 


  return (
    <nav className="mt-5">
      <ul className="pagination justify-content-center">
        { pageArr }
      </ul>
    </nav>
  )
}

export default Pagination




