import React, { useState, useEffect } from "react"
import { ToastContainer, toast } from 'react-toastify'

import Navbar from '../../components/AppNavbar'
import Header from '../../components/Header'
import LinkForm from './LinkForm'


function LinkAdd() {

  return (
    <div className="main_container bg-dark">
      <Navbar />
      <ToastContainer />
      <div className="container bg-dark mt-5">
        <div className="row">
          <div className="offset-2 col-8">
            <Header title="Добавить ссылку" />
            <LinkForm/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LinkAdd;