import React, { useState } from "react"

import { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap'

import { useAuth } from '../hooks/Auth.hook'


function AppNavbar() {

  const { signout, getUserName, getIsAdmin } = useAuth()

  const [userName, setUsername] = useState(getUserName())
  const [isAdmin, setIsAdmin] = useState(getIsAdmin())

  function handleOnClick(event) {
    event.preventDefault()
    console.log("You have logged out!")

    signout()
    window.location.replace("/signin")
  }

  return (
    <Container fluid>
      <Navbar bg="dark" expand="lg">
        <Navbar.Brand href="/links">LinkShorter</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/links">Ссылки</Nav.Link>
          </Nav>
          <Navbar.Text>
            <Nav className="me-auto">
              <span style={{display: "block", padding: 8}}>{ userName }</span>
              <Nav.Link onClick={handleOnClick} href="#">Выйти</Nav.Link>
            </Nav>
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  )
}

export default AppNavbar;