import React, { useState, useEffect } from "react"
import { useParams, Link } from 'react-router-dom'

import Header from '../../components/Header'
import DateCell from '../../components/DateCell'

import ClickEditModal from './ClickEditModal'

import { Container, Row, Col, Table } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'

import { useAxios } from '../../hooks/Axios.hook'


function ClickList() {

  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col>
            <Header title="Список срабатываний" />
            <ClickTable/>
          </Col>
        </Row>
      </Container>
    </>
    )
  }


function ClickTable() {

  const { getAxiosInstance } = useAxios()

  const [clicks, setClicks] = useState([]);

  const params = useParams();

  useEffect(() => {

    const url = `/clicks/link/${ params.link_id }`

    getAllClicks()

  }, []);


  function getAllClicks() {
    const url = `/clicks/link/${ params.link_id }`

    getAxiosInstance.get(url)
      .then((response) => {
        const respData = response.data;
        setClicks(respData);
      }).catch((error) => {
        
        if (error.response.status === 401) {
          console.log(error)
        } else {
          toast(`Произошла ошибка: ${error}`)
          console.log(error)
        }
        
      });
  }
  
  

  return (
    <>
      <div className="mb-5">
        <Table hover variant="dark">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Тестовое срабатывание</th>
              <th scope="col">ID ссылки</th>
              <th scope="col">Комментарий</th>
              <th scope="col">UserAgent</th>
              <th scope="col">Дата создания</th>
              <th scope="col">Дата изменения</th>
              <th scope="col">Действия</th>
            </tr>
          </thead>
          <tbody>
            { (clicks.length > 0)
              ? clicks.map(click => 
                  
                <tr key={click.id}>
                  
                  <th scope="row">{click.id}</th>
                  <td>{ (click.is_fake) ? "Да" : "Нет" }</td>
                  <td>{ click.link_id }</td>
                  <td>{ click.comment }</td>
                  <td>{ click.user_agent }</td>
                  <td><DateCell date={click.created_at} /></td>
                  <td><DateCell date={click.updated_at} /></td>
                  <td>
                    <ClickEditModal click_id={ click.id } getAllClicks={getAllClicks} />
                  </td>
                </tr>
                
              )
            : <tr><td colSpan="7">Нет данных</td></tr>
          }
          </tbody>
        </Table>
      </div>
    </>
  )

}

export default ClickList;