import {useState, useCallback, useEffect} from 'react'
import jwt_decode from 'jwt-decode'

export const useAuth = () => {

  const accessTokenStorageName = 'accessToken'
  const refreshTokenStorageName = 'refreshToken'

  const signin = (accessToken, refreshToken) => {
    localStorage.setItem(accessTokenStorageName, accessToken)
    localStorage.setItem(refreshTokenStorageName, refreshToken)
  }

  const signout = () => {
    localStorage.removeItem(accessTokenStorageName)
    localStorage.removeItem(refreshTokenStorageName)
  }

  const getAccessToken = () => {
    const data = localStorage.getItem(accessTokenStorageName)
    if (data) return data
    return null
  }

  const getRefreshToken = () => {
    const data = localStorage.getItem(refreshTokenStorageName)
    if (data) return data
    return null
  }
  

  const getUserName = () => {
    const accessToken = localStorage.getItem(accessTokenStorageName)
    if (accessToken) {
      const decodedToken = jwt_decode(accessToken)
      return decodedToken.UserName
      
    }
    return null
  }

  const getIsAdmin = () => {
    const accessToken = localStorage.getItem(accessTokenStorageName)
    if (accessToken) {
      const decodedToken = jwt_decode(accessToken)
      return decodedToken.IsAdmin
    }
    return false
  }

  const getUserId = () => {
    const accessToken = localStorage.getItem(accessTokenStorageName)
    if (accessToken) {
      const decodedToken = jwt_decode(accessToken)
      return decodedToken.UserId
    }
    return null
  }

  const getIsAccessTokenExpired = () => {
    const accessToken = localStorage.getItem(accessTokenStorageName)
    if (accessToken) {
      const decodedToken = jwt_decode(accessToken)
      const now = Math.floor(Date.now() / 1000)
      const exp = new Date(decodedToken.exp).getTime()
        
      return now > exp
    }
    return false
  }

  return { signin, signout, getAccessToken, getRefreshToken, getUserName, getIsAdmin, getIsAccessTokenExpired }

}