import React from 'react'

import { Button } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'

function ButtonCopyLink(props) {
  return(
    <Button onClick={
      () => {
        if (window.isSecureContext && navigator.clipboard) {
          navigator.clipboard.writeText( props.short_url )
          toast('Ссылка скопирована в буфер обмена')
        } else {
          toast('Ошибка копирования в буфер обмена')
        }
      }
    }>
      Скопировать ссылку
    </Button>
  )
}



export default ButtonCopyLink